
@font-face {
  font-family: 'Bokonique-Bold';
  src: url('../fonts/Bokonique/Bokonique-Bold.ttf') format('truetype'),
       url('../fonts/Bokonique/Bokonique-Bold.woff') format('woff'),
       url('../fonts/Bokonique/Bokonique-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bokonique-Regular';
  src: url('../fonts/Bokonique/Bokonique-Regular.ttf') format('truetype'),
       url('../fonts/Bokonique/Bokonique-Regular.woff') format('woff'),
       url('../fonts/Bokonique/Bokonique-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bokonique-Light';
  src: url('../fonts/Bokonique/Bokonique-Light.ttf') format('truetype'),
       url('../fonts/Bokonique/Bokonique-Light.woff') format('woff'),
       url('../fonts/Bokonique/Bokonique-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DejaVuSans-Bold';
  src: url('../fonts/DejaVuSans/DejaVuSans-Bold.ttf') format('truetype'),
       url('../fonts/DejaVuSans/DejaVuSans-Bold.woff') format('woff'),
       url('../fonts/DejaVuSans/DejaVuSans-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DejaVuSansCondensed';
  src: url('../fonts/DejaVuSans/DejaVuSansCondensed.ttf') format('truetype'),
       url('../fonts/DejaVuSans/DejaVuSansCondensed.woff') format('woff'),
       url('../fonts/DejaVuSans/DejaVuSansCondensed.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DejaVuSans';
  src: url('../fonts/DejaVuSans/DejaVuSans.ttf') format('truetype'),
       url('../fonts/DejaVuSans/DejaVuSans.woff') format('woff'),
       url('../fonts/DejaVuSans/DejaVuSans.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
