*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  overflow-x: hidden;
  position: relative;
  height: 100%;
}
.p {}
.myprog{
  margin-left: 3.9vw !important;
}
.trgshow{
  display: none;
}
.trgshow--desktop {
  display: block;
}
.BurgerMenu{
  display: none;
}
.posfixer{
  padding-left: 0 !important;
  /* opacity: 0; */
  position: fixed!important;
  z-index: 1000;
}
.fixc{
  /* margin-top: -0.3vw; */
}

select:focus{
  outline: none;
}
.fposfix{
  
padding-bottom: 6vw;
}
.Clicker{
  /* position: absolute; */
  position: absolute;
    width: 100%;
    height: 100%;
    background: red !important;
}
.ClickerIs{
  background: red;
  height: 100%;
  width: 100%;

}
.LeftMenuContainer ul{
  list-style-type:none;
}
.LeftMenuContainer ul li{
  margin-top: 2vw;
}
.LeftMenuContainer ul li span{
  /* font-family: 'Bokonique-Bold'; */
  font-family: 'Bokonique-Bold';
    font-size: 1vw;
    color: black;
    padding-left: 0vw;
    text-transform: none;
    cursor: pointer;
}
.LeftMenuContainer ul li span:hover{
  color: #00CB8E;
}
.LeftMenuContainer p{
  /* font-family: 'Bokonique-Light'; */
  font-family: 'Bokonique-Light';
    color: #585858;
    /* font-weight: bold; */
    font-size: 1.2vw;
}
.My-Container{
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100vh;
  /* opacity: 0.5; */
  color: white;
  vertical-align: middle;
  text-transform: uppercase;
  background-size: cover;
  
  background-image: url('../../views/images/Photos/Home_bg.jpg');
  /* background: #fff !important; */
  /* background-position-y:-5.7vw; */
}
.climat_title{
    font-size: 1.1vw;
    text-transform: none;
    font-family: 'Bokonique-Bold';
}
.Climat_Body{
  display: flex;
  font-family: 'Bokonique-Light';
  width: 100%;
  /* height: 100%; */
  margin-top: 0.7vw;
  
}
.ClimatLocation{
  text-transform: none;
    padding: 0.8vw;
    /* color: black !important; */
    width: 14.1vw;
    font-size: 0.8vw;
}
.ctdis{
    width: 6.5vw;
    text-align: left;
    text-transform: none;
    font-size: 0.7vw;
    margin-right: 1.2vw;
    padding-top: 0.3vw;
    display: flex;
}
.ctdis p{
  margin: 0;
  height: fit-content;
  align-self: center;
}
.ContainerMiddle p:nth-child(1){
  color: black;
  font-weight: bold;
}
.ctdis p:nth-child(2){
  color: #8E8E8D;
}
.mieder{

}
.eder p{
      margin-top: -0.1vw !important;
      margin-left: 0.2vw !important;
      font-size: 0.8vw;
}
.disul{
  list-style-type: none;
}
.ClimatLocation>p{
  margin-left: 0.7vw;
}
.climat_context_container{
  /* border: 1px solid #F9F8F7; */
  /* background-color: greenyellow; */
  /* opacity: 0.5; */
  height: 14.6vw;
    position: relative;
  width: 61.3%;
  margin-right: 0.9%;
}
.mieder{
  font-family: 'Bokonique-Regular';
}
.mieder p{
  margin-left: 1vw !important;
  margin-top: 0vw !important;
  
}
.mieder p:nth-child(1){
  font-weight: bold;
  /* color: red; */
  /* height: 1vw; */
  color: black !important;
  height: 1vw;
  font-size: 1vw;
}
.mieder p:nth-child(2){
  color: #8E8E8D;
}
.pl{
  /* font-weight: bold; */
    margin-left: 0.7vw;
    font-family: 'Bokonique-Regular' !important;
  font-weight: bold;
}
.mieder p:nth-child(2){
  /* color: red; */
  /* height: 1vw; */
  /* height: 1vw; */
  font-size: 0.9vw;
}
.mieder p::after{
  content: '°';
}
.cntend p{
  color: black !important;
  font-weight: bold;
}
.cntend{
  margin-top: -1.3vw;
  font-size: 0.7vw !important;
  align-self: flex-end;
}
.ClimatImg {
  position: relative;
  height: 1.9vw;
  top: -2.3vw;
  left: 5.2vw;
}
.cntend p{
  /* background: red ; */
}
.sombl::after{
  content: 'o';
  font-size: 1.2vw;
  font-weight: bold;
  position: absolute;
}
.sombl{
  font-family: 'Bokonique-Bold';
  color: black !important;
  font-size: 1.8vw;
  margin-bottom: 0 !important;
  margin-top: -0.1vw !important;
}
.BottomClimatContextBlock{
  height: 7.7vw;
  background: #F8F6F5;
  /* opacity: 0.5; */
  width: 52.6vw;
}
.eder img{
  display: block;
  /* width: 100%; */
  height: 55.3%;
  margin-left: 1vw;
  margin-top: 1.2vw;
}
.eder{
  padding-left: 0;
  width: 6.5vw;
  padding-top: 0;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}
.MidlleClimatContextBlock{
  height: 4vw;
  display: flex;
  text-align: left;
  background: #F8F6F5;
  color: black !important;
  /* opacity: 0.5; */
  width: 52.6vw;
}
.MidlleClimatContextBlock p{
  color: #8E8E8D;
  padding: 0;
  margin-bottom: 0.4vw;
  margin-left: 0.8vw;
  margin-top: 0.3vw;
  text-transform: capitalize;
}
.ContainerMiddle{
  text-transform: none;
  padding: 0.8vw;
  width: 14.1vw;
  font-size: 0.8vw;
}
.ClimatBlock{
  display: flex;
  border-top: 1px solid #F9F8F7;
  color: black;
  background: #fff;
  height: 2.9vw;
  width: 52.6vw;
}
.ClimatBlock div{
  /* color: darkturquoise; */
}

.climat_Image_Container{
    position: relative;
    /* width: 28.8%; */
    width: 26.1vw;
    height: 14.6vw;
    /* background-image: url('../../views/images/Photos/img_3.png'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.ClimateContainer{
  position: relative;
  color: black;
  padding-left: 9vw;
  height: 17vw;
  overflow: hidden;
  margin-top: 9vw;
}
.My-Container >h2{
  color: #fff;
}
.Aygi_title{
  font-size:4.2vw;
  margin-bottom: 0.4vw;
}
.My_Logo{
  position: absolute;
  background-image: url('../../views/images/logos/Logo.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  width: 5.4vw;
  z-index: 100;
  height: 3.7vw;
  margin-left: 9.1vw;
  margin-top: 1.1vw;
}


.center_btn {
  background-color: #5BCA90;
  text-transform: uppercase;
  color: white;
  border: none;
  border-radius: 1.9vw;
  /* width: 18.37vw; */
  width: 14.37vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4vw;
}

.center_btn a {
  box-sizing: border-box;
  text-decoration: none;
  color: white;
  font-size: 1vw;
  white-space: nowrap;
  /* padding: 1.1vw; */
  padding: 1.3vw;
  display: block;
}

.footer_texts_block {
  text-align: left;
  position: relative;
  top: 0.4vw;
}

.Mini_f{
  text-align: left;
  margin-top: 0.4vw;
  height: 3vw;
  color: #A4A4A4;
}
.Mini_f span:nth-child(1){
  font-size: 1.5vh;
  
}
.Mini_f span:nth-child(2){
  position: relative;
  float: right;
  left: -8vw;
  font-size: 1.5vh;
}

.Mini_f span:nth-child(2) a{
  color: #A4A4A4;
}
.Container_Texts{
  font-family: 'Bokonique-Bold';
  /* font-size: 5vw; */
  /* background: red; */
  position: relative;
  top: 37%;
  text-align: center;
  /* padding-top: 10vw; */
}
.aygi_idea{
  font-size: 1.3vw;
  margin-left: -0.3vw;
  margin-bottom: 2vw;
}
.Routing_nav{
  position: absolute;
  /* height: 10.2%; */
  /* position: relative; */
  /* margin-top: 31.3vw; */
  padding-left: 9.1vw;
  overflow: hidden;
  /* width: 100%; */
  /* background: red; */
  bottom: 0;
  width: 100%;
}
.MyContainers{
  display: flex;
  flex-wrap: wrap;
}
.Routes_Container{
      /* height: 100%; */
      /* overflow: hidden; */
    display: flex;
}

.Route_Link_Block{
  /* background: gray;  */
  /* background: gray; */
  /* overflow: hidden; */
  text-align: center;
  /* height: 100%; */
  /* float: left; */
  /* float: left; */
  width: 20.5vw;
  text-align: center;
}

.Route_Link_Block:hover{
/* box-shadow: 10px 5px 5px red; */
z-index: 1000;
box-shadow: 0 1vw 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22), 0 10px 10px rgba(0,0,0,0.22);
/* transform: scale(1.5);  */
}
.menu {
  display: flex;
  list-style-type: none;
  justify-content: space-between;
  width: 88%;
  z-index: 999999;
}
.HeaderBlock{

}
.HedaerContainer{
  /* position: relative; */
  overflow: hidden;
  position: relative;
  /* padding: 2vw; */
  padding-top: 2.6vw;
  padding-left: 18vw;
}
/* 
 */
/* .optim{
  margin-left: 2vw !important;
} */
.hrl{
  margin-left: -8.9vw;
  /* position: absolute; */
}
.menu_next{
  background-image: url('../../views/images/Icons/nexter.png');
  background-position: center;
  background-size: 100%;
  /* background-color: red; */
  height: 0.8vw;
  width: 0.4vw;
  margin-left: 0.7vw;
  background-repeat: no-repeat;
  position: relative;
  /* z-index: 5000; */
  float: right;
}
.SeterBlock{
    /* margin-left: 24.8vw; */
    margin: auto;
    display: flex;
    width: fit-content;
    /* padding-top: 5.2vw; */
    padding-top: 5.2vw;
    margin-bottom: 90px;
}
.TitleBlock{
  background-image: url('../../views/images/Photos/Hraparakumner.jpg');
  background-repeat: no-repeat;
  text-align: center;
    font-size: 2.5vw;
    text-transform: none;
    font-family: 'Bokonique-Bold';
  background-size: cover;
  color: #fff;
  background-position: center;
  /* opacity: 0.25; */
  margin-top: 1.3vw;
  height: 15.8vw;
  position: relative;
}
.iconer{
  /* background-color: red; */
  height: 1vw;
  background-repeat: no-repeat;
  width: 1vw;
  /* float: left; */
}
.progIkerWhite1{
  padding-left: 4.1vw !important;
}
.p2{
  padding-left: 4.1vw !important;
}
.p1{
  padding-left: 6vw !important;
}
.MyWrite1{
  background-image:url('../../views/images/Icons/Newspaper.png') !important;
  /* background-color: red; */
}
.pb1{
  background-image: url('../../views/images/Icons/Company_2.png') !important;
  left: 1.9vw !important;
}
.IsWriter1{
  /* background-color: red; */
  background-image: url('../../views/images/Icons/Company-icon.png') !important;
  left: 1.9vw !important;
  /* padding-left: -4.4vw; */
}
.IsWriter2{
  float: right !important;
  background-image: url('../../views/images/Icons/Government_2.png') !important;
  left: -4.4vw !important;
}
.pb2 {
  float: right !important;
  position: relative;
  z-index: 0;
  background-image: url('../../views/images/Icons/Government-icon.png')!important;
  left: -4.4vw !important;
}
.ike{
  float: left;
  position: relative;
  left: 4.1vw;
  top: 1.3vw;
  height: 2.2vw;
  width: 2.1vw;
  background-size: contain;
  BACKGROUND-POSITION: CENTER;
  z-index: 0;
  background-color: none;
  background-image: url('../../views/images/Icons/ezgif.com-gif-maker.png');
}

.IsWhiter1{
  pointer-events: none;
  background-image: url('../../views/images/Icons/Newspaper.png') !important;
  /* background: red !important; */
}
.IsWhiter2{
  background-image: url('../../views/images/Icons/Write_2s.png') !important;
}
.buter{
  text-decoration: none;
  overflow: hidden;
  background: none;
  /* border-radius: 0.2vw; */
  /* border-color: red; */
  border-right: 0.1vw solid;
    border-top: 0.1vw solid ;
    border-left: 0.1vw solid ;
    border-bottom: 0.1vw solid ;
  /* padding: 1.6vw; */
  /* width: 25.1vw; */
  /* opacity: 0.5; */
  /* text-align: center; */
  /* background: red; */
  width: 25.2vw;
  color: #30483C;
  font-family: 'Bokonique-Regular';
  /* border: 0.1vw solid #30483C; */
  /* border-radius: 0.2vw; */
  background: #fff;
  /* padding-bottom: 1.1vw; */
}
.reder{
  color: red;
}
.t1{
  /* margin-left: -4.3vw; */
  /* margin-left: 6.9vw; */
  padding-left: 8vw !important;
  color: black;
  text-decoration: none;
}
.t2{
  /* margin-left: -4.3vw; */
  padding-left: 5.5vw !important;
  /* margin-left: 6.9vw; */
  color: black;
  text-decoration: none;
}
.bl_2{
  border-left: none;
}

.bl_1{
  /* padding-right: 3.1vw; */
  background: #fff;
  /* border-color: red; */
  /* padding-right: 3.1vw; */

  border-right: none;
}

.b2{
  float: right;
  position: relative;
  z-index: 0;
    background-image: url('../../views/images/Icons/Write.png') ;
    left: -2.5vw;
}
.buter a{
  text-decoration: none;
  font-size: 1.2vw;
  position: relative;
  display: block;
  color: #30483C;
  /* overflow: hidden; */
  z-index: 1000;
  /* margin-left: 3.8vw; */
  padding: 1.4vw;
  /* padding-left: 10.5vw; */
    /* margin-left: 3.8vw; */
}
.ctext{
  POSITION: RELATIVE;
    top: 5.6vw;
}
.dropdown a{
  text-decoration: none;
  text-transform: none;
  color: #4C5950;
  /* color: #5BCA90; */
  /* color: #3B4A3F; */
    font-size: 1vw;
}
.menu .dropdown:nth-child(1) a {
  font-size: 1.3vw;
}
.menu > li {
  /* display: inline-block; */
  font-family: 'DejaVuSans-Bold';
    list-style-type: none;
    position: relative;
    align-items: center;
    display: flex;
  /* padding: 1vw; */
  
  font-family: 'DejaVuSans-Bold';
  list-style-type: none;
  position: relative;
  /* margin-right: 1.3vw; */


}
.submenu{
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: absolute;
  display: none;
}
.submenu a{
  display: block;
}
.aygitem{
  /* -webkit-box-shadow: 10px 10px 5px -12px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 5px -12px rgba(0,0,0,0.75); */
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.07);
  /* border: 1px solid #F2F2F2; */
  overflow: hidden;
  /* overflow: hidden; */
  margin-left: 0vw;
  /* height: 11vw; */
  /* box-sizing: ; */
  margin-top: -2.8vw;
  margin-right: 1.5vw;
  margin-bottom: 5.4vw;
  width: 28.9%;
  position: relative;
  opacity: 1;
  height: 16.7vw;
  /* padding: 10vw; */
  background: none;
  /* border: 1px solid black; */
  text-align: center;
  /* border: 1px solid black; */
  text-align: center;  
}
.psfix{
  margin-top: 7vw;
}
.flex-item{
  overflow: hidden;
  /* overflow: hidden; */
  margin-left: 0vw;
  /* height: 11vw; */
  margin-top: -2.8vw;
  margin-right: 1.64vw;
  margin-bottom: 5.4vw;
  width: 29.2%;
  position: relative;
  opacity: 1;
  height: 16.7vw;
  /* padding: 10vw; */
  /* border: 1px solid black; */
  text-align: center;
  /* border: 1px solid black; */
  text-align: center;
}
.flex-item{
  background-color: unset;
  /* background-image: url('../../views/images/Home_Mobile.png'); */
}
.flex-item:nth-last-child(-n + 3){
  margin-bottom: 0;
}

.publication__title, .consulting__title {
  position: relative !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  color: white;
}

.consulting__atag:hover {
  text-decoration: none;
}

.publications .flex-item{
  margin-right: 2.6vw;
}

.publications .mycontext p {
  color: white;
}

.Shadow img{
  display: block;
  height: 100%;
  width: 100%;
}
.mycontext{
  z-index: 20;
  position: absolute;
  /* padding-top: 3vw; */
  /* align-self: flex-start; */
  height: 100%;
  top: 0;
  /* padding-top: 58px; */
  width: 100%;
}
.showLink:hover{
  color: #83d7ab;
}
.mycontext .center_btn{
  position: relative;
  opacity: 0;
  outline: none;
  top: 2vw;
  /* margin-top: 0.5vw; */
}
.agnb{
  top: -11% !important; 
}
.mycontext p, .mycontext .center_btn{
  font-family: 'Bokonique-Bold';
}
.flex-item:hover .center_btn{
  /* display: block; */
  transition: all 0.3s;
  opacity: 1;
  /* transition: top 0.4s; */
  /* background: red; */
}
.aygitem:hover .Shadow{
  /* top: 5vw; */
  background: rgba(0,0,0,0.32) !important;
  /* transition: top 0.4s; */
  /* background: red; */
}
.aygitem:hover .DisCriptionBlock .cdp{
  color: #00CB8E;
}
.aygitem:hover .DisCriptionBlock .fa-angle-right{
  opacity: 1;
}
.aygitem .fa-angle-right{
  opacity: 0;
}
.aygitem:hover{
  transition: 0.3s ;
  cursor: pointer;
  /* -webkit-box-shadow: 10px 10px 56px -33px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 56px -33px rgba(0,0,0,0.75); */
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.2);
  

}
.aygitem:hover .center_btn{
  opacity: 1;
  /* transition: all 1s; */
}
.flex-item:hover p{
  top: 1.8vw;
  transition: top 0.4s;
  /* background: red; */
}
.flex-item:hover .Shadow{
  top: 5vw;
  background: none;
  /* transition: top 0.4s; */
  /* background: red; */
}
.mycontext p{
  /* text-transform: none; */
  text-transform: none;
  
    position: relative;
    top: 4vw;
    font-size: 1.3vw;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 8.9vw;
  width: 100%;
  /* justify-content: space-between; */
  /* background-color: DodgerBlue; */
}
.grid-container{
  /* background: yellow;  */
  
    display: grid;
    left: 9vw;
    top: 0.5vw;
    position: relative;
    grid-column-gap: 1vw;
    grid-template-columns: 26.3vw ;
    grid-template-rows: 13.4vw 13.4vw 13.4vw;
    /* grid-template-columns: repeat(3, 1fr); */
    
    grid-column-gap: 1.5vw;
    grid-row-gap:1vw ;

}

.img_container{
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center;
}
.img_container img{
  width: auto;
  /* height: 100%; */
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.aygitem .Shadow{
  position: relative;
  width: 100%;
  z-index: 10;
  height: 100%;
  background: rgba(0,0,0,0.32);
    /* background-color: red; */
    /* opacity: 0.5; */
    /* background: red;
  
    /* background-color: red; */
  /* opacity: 0.5; */
  /* background: red; */
}
.flex-item .Shadow{
  position: relative;
  width: 100%;
  z-index: 10;
  height: 100%;
  background: rgba(0,0,0,0.32);
    /* background-color: red; */
    /* opacity: 0.5; */
    /* background: red;
  
    /* background-color: red; */
  /* opacity: 0.5; */
  /* background: red; */
}
.fcr{
  padding-left: 4vw;
}
.flexfixer{
  padding-left: 0.8vw;
}

.publications--href:hover {
  color: white;
  text-decoration: none;
}

.flexfixer.training .rl {
  width: 100%;
  margin-left: 7.9vw;
}

.flexfixer.training {
  padding-left: 8.2vw;
}

.flexfixer.training img {
  max-width: 100%;
}

.InMenu{
  /* opacity: 0.5; */
  /* background: red; */
  display: flex;
}
.flexItemMenu{
margin-top: 0.6vw;
    height: 16.6vw;
    width: 27.3vw;
    /* margin-bottom: 17vw; */
    margin-bottom: 2vw;
}

.flexItemMenu__publication {
  margin-top: 1.6vw;
}

.cdp{
  margin-right: 1.4vw;
}
.cdp_2{
  left: 0 !important;
}
.rl{
  font-size: 1vw;
  font-family: 'Bokonique-Regular';
}

.rb {
  width: 37vw;
  /* width: 38.5vw; */
  font-family: 'Bokonique-Bold';
  font-size: 1vw;
  display: block;
  color: black;
  margin-top: -0.2vw;
  margin-left: 26vw;
}

.rb a {
  color: #00CB8E;
  /* color: black; */
}

.rb a:hover{
  color: #00CB8E;
  text-decoration: none;
}
.rb .desc{
  font-size: 1vw;
  font-family: 'Bokonique-Regular';
  margin-top: 1vw;
}
.SeachResults.tragr{
  right: unset;
  font-size: 1.2vw !important;
  left: 10.5vw !important;
}

.SeachResults.tragr.training:nth-child(1){
  left: -15vw !important;
}

.SeachResults.tragr.training:nth-child(2){
  left: 21.9vw !important;
}
.MyIcon{
  position: relative;
  height: 1vw;
  opacity: 0;
  background-image: url('../../views/images/Icons/Out.png');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1vw;
  left: 88.5vw;
  top: 0.5vw;
}
.DataCont{
  text-transform: none;
  text-align: left;
}

.trainingItem .DataCont .rb .desc p {
  margin-bottom: 0px;
  line-height: 22px;
  /* margin-bottom: 1rem; */
  /* line-height: 1.9rem; */
}

.trainingItem .DataCont .rb .MyButtonShow {
  margin-top: 1rem;
}

.noner{
  transition: opacity 0.5s;
  opacity: 0 !important;
}
.contexter{
/* margin-left: 7.9vw; */
color: black;
    margin-top: -0.3vw;
    display: flex;

}

.trainingItem{
  display: block;
  border-radius: 0.2vw;
  /* border-bottom: 1px solid #EBEBEB; */
  margin-top: 3vw;
  height: 15.5vw;
  width: 54.9vw;
  height: 7.3vw;
  margin-bottom: -0.8vw;
  margin-left: 0.2vw;
  /* background: grey; */
}
.trainingItem::after{
  content: " ";
  display: block;
  position: relative;
  height: 1px;
  background: #F2F2F2;
  width: 81.9vw;
  left: 7.6vw;
  top: 31px;
}
.Mytexts{
  padding: 1vw;
  padding-left: 3vw;
}

.Mytexts--services {
  padding: 0;
  padding-left: 3vw;
}

.Mytexts p:nth-child(1) {
  width: 70%;
  text-transform: none;
  margin-bottom: 0.6vw;
  font-family: 'Bokonique-Bold';
  font-size: 1.5vw;
  /* color: red; */
}

.Mytexts p:nth-child(2){
  text-transform: none;
  font-family: 'Bokonique-Light';
  font-size: 1vw;
  /* color: yellow; */
}
.ImageBlock{
  background: gary;
  height: 9vw;
  width: 9vw;
  position: relative;
  overflow: hidden;
  float: right;
  top: -4vw;
  left: -4vw;
  border-radius: 5vw;
}
.ImageBlock>img{
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.TopTitleBlock{
  background: #00CB8E;
  height: 6.7vw;
}
.InBottomText{
  position: relative;
  top: 5.5vw;
}
.ProfileDis{
  width: 100%;
  padding: 2vw;
  position: relative;
  /* padding-top: 2vw; */
    /* padding-bottom: 2vw; */
  color: black;
  height: 77%;
  background: #fff;
}
.TextDivs{
  height: auto;
  margin-bottom:1vw;
  display: flex;
    /* padding-left: 3vw; */
}
.InBottomText{
  height: 2vw;
  display: flex;
  padding-left: 3vw;
}

.ProfileBlock .TextDivs .txtmy__email {
  font-family: 'Bokonique-Light';
}

/* .ProfileBlock .TextDivs .txtmy__email a {
  color: black;
  text-decoration: none;
} */

.TextDivs p:nth-child(1){
  text-transform: none;
  font-size: 1vw;
  margin: 0;
  width: 38%;
  /* margin-right: 3vw; */
  font-family: 'Bokonique-Light';
}
.ProfileDis>.TextDivs:nth-child(1)>p:nth-child(1){
  width: 57.5%;
}
.fa-chevron-left{
  display: none;
}
.InBottom{
  margin-top: 5.7vw;
}
.TextDivs p:nth-child(2) {
  text-transform: none;
  font-size: 1vw;
  /* position: absolute; */
  /* left: 18vw; */
  width: 60%;
  margin: 0 0 0 auto;
  display: block;
  font-family: 'Bokonique-Bold';
}

.videoPlayer{
  background: none !important;
  width: 71.4vw !important;
    height: 33.5vw !important;
}


#VideoCloser:hover{
  background: #3b4a3f;
}
#VideoCloser{
  opacity: 1;
  top: -15.8vw;
  height: 2vw;
  width: 2vw;
  left: 73.4vw;

}
.close {
  position: relative;
  right: -2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -0.9vw;
  cursor: pointer;
  opacity: 1;
  /* background: green; */
  width: 1.4vw;
  height: 1.4vw;
}
.videoPlayer iframe{
  border: none;
  width: 100%;
  height: 100%;
}
.close:hover {
  /* opacity: 1; */
}
.close:before, .close:after {
  position: absolute;
  /* left: 1vw; */
  content: ' ';
  height: 1.1vw;
  width: 0.1vw;
  background-color: white;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.ProfileBlock {
  /* background: yellow; */
  height: fit-content;
  width: 50vw;
}
.ProfileContainer{
display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
    /* background: red; */
    position: fixed;
    width: 100vw;
    /* display: none; */
    height: 100vh;
    z-index: 10000;
    top: 0;
}
.myres{
  padding-top: 0.5vw !important;
    left: 35.3vw !important;
}
.MyBodyContainer{
  /* background: red; */
  /* opacity: 0.5; */
  /* color: rebeccapurple; */
  margin-top: 4.8vw;
}
.progr{
  margin-left: 3.9vw !important;
  margin-top: -0.3vw !important;
}
.progItem .ImageBlockContainer{
  overflow: hidden;
  /* background: yellow; */
  /* background: yellow; */
  height: 8.5vw;
  width: 13.5vw;
  position: relative;
  background-size: cover;
  background-position: center;
}

.progItem .MyInfoContainer{
  display: flex;
  flex-wrap: wrap;
  /* height: 8vw; */
  width: calc(100% - 14.8vw);
}

.progItem .MyButtonShow{
  height: fit-content;
  align-self: flex-end;
}

.progItem .MyButtonShow a{
  display: flex;
  font-size: 0.9vw;
  color: black;
  margin-left: 2.2vw;
  margin-top: 0.6vw;
  font-family: 'DejaVuSans-Bold';
  /* font-family: 'Bokonique-Bold'; */
}

.progItem .MyButtonShow a.customLink {
  color: #00CB8E;
}

.progItem .TitleInfo{
  height: fit-content;
  font-family: 'DejaVuSans-Bold';
  /* font-family: 'Bokonique-Bold'; */
  font-size: 1vw;
  color: black;
  margin-left: 2.2vw;
  display: block;
}

.gover .progItem .TitleInfo {
  width: 90%;
}

.progItem .MyButtonShow a:hover {
  color: #00CB8E !important;
  text-decoration: none;
}

.ImageBlockContainer img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mytext_1 {
  color: black;
  font-family: 'DejaVuSansCondensed';
  text-align: left;
  margin-left: 2.2vw;
  font-size: 1vw;
  margin-top: 0.5vw;
  /* width: 38.3vw; */
  text-transform: none;
}

.mytext_1 p {
  line-height: 21px;
}

.titl{
  font-family: 'Bokonique-Bold';
  font-size: 1vw;
  /* background: red; */
  /* text-align: left; */
  color: black !important;
  margin-left: 2.2vw;
  display: block;
}
.titl:hover{
  color: #00CB8E !important;
  cursor: pointer;
  text-decoration: none;
}
.swimg{
  background-image: url('../../views/images/Icons/Out.png');
  height: 1.1vw;
  width: 1.4vw;
  /* background-color: red; */
  display: flex;
  background-repeat: no-repeat;
  background-size: 0.9vw;
  margin-left: 0.3vw;
  margin-top: 0.1vw;
}
.showLink{
  display: flex;
  font-family: 'DejaVuSans-Bold';
  color: #83d7ab;
  margin-left: 2.2vw;
  font-size: 1vw;
  /* color: red; */
  width: 11vw;
}

.showLink--desktop {
  color: #83d7ab !important;
  width: 100%;
}

.mprogm{
  text-transform: uppercase !important;
  margin: 0 !important;
  left: -19.1vw !important;
  font-size: 1.3vw !important;
  padding-top: 0 !important;

}
.fxt{
  margin-top: 0 !important;
}
.MyInfoContainer{
  /* background: green; */
  width: 100%;
  height: 100%;
}
.MyButtonShow{
  /* background: ghostwhite; */
  height: 1.2vw;
}
.prgInfo{
  width: 82% !important;
  height: 11.3vw !important; 
}
.TitleInfo{
  width: 45vw;
  height: 3.4vw;
}

.gover .TitleInfo.compImgTitle{
  display: none;
}
.gover .MyInfoContainer{
  width: 45vw;
  height: fit-content;
}
.InfoTextContainer {
  width: 91% !important;
  height: fit-content !important;
}
.progm{
  width: 34vw !important;
}
.LeftMenuContainer{
  width: 36vw;
  /* height: 32vw; */
  /* background: green; */
  margin-left: 9vw;
}
.titlprog{
  font-family: 'Bokonique-Bold';
    font-size: 1vw;
    text-align: left;
    color: black;
    text-transform: none ;
    /* margin-left: 2.2vw; */
    text-align: left;
    margin-left: 8.8vw;
    margin-top: -0.3vw;
    width: fit-content;
}
.prognameTtitle{
  width: 100% ;
  height: 100% ;
}
.ProgNameContainer{
  width: 100vw;
}
.progFlex{
  padding-left: 3.2vw;
}
.progFlex2{
  padding-left: 0vw !important;
}
.regcont{
  display: flex;
}
.karpos{
  width: 94.8vw !important;
  margin-top: 3.8vw;
}

.showLink {
  display: flex;
  font-family: 'Bokonique-Bold';
  color: #83d7ab;
  margin-left: 0;
  text-transform: uppercase;
  font-size: 1vw;
  /* color: red; */
  width: 50.6vw;
}

.progItem{
  display: flex;
  border-radius: 0.2vw;
  /* margin-top: 4.1vw; */
  margin-top: 2.1vw;
  height: 15.5vw;
  width: 57vw;
  height: fit-content;
  margin-bottom: 2.29vw;
  /* margin-bottom: -0.8vw; */
  margin-left: 0.2vw;
  /* background: grey; */
}
.aygishadow{
  height: 15.5vw !important;
  background:none !important;
}
.aygmenu{
  /* background: green; */
  /* color: black !important; */
  /* opacity: 0.5; */
  width: 18.8vw;
}
.aygitem ul li a{
  color: black !important;
}
.aygi-context{
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 77%;
  padding-top: 3vw;
}
.aygitemImg{
  height: 15.5vw;
}
.DisCriptionBlock{
  display: flex;
  background: white;
  /* height: 4.7vw; */
  /* z-index: 0 !important; */
  width: 100%;
  height: 23%;
  align-items: center;
  bottom: 0;
  position: absolute;
  /* top: 15.5vw; */
  z-index: 1000;
  left: 0;
  right: 0;
}

.garden .DisCriptionBlock .dp {
  margin: 0px 0px 0px 20px;
  width: 100%;
}

.garden .DisCriptionBlock .dp_2 {
  position: absolute;
  right: 5px;
  bottom: 0px;
  margin-bottom: 3px;
  width: auto;
}

.agnb{
  background-color: #5BCA90;
  text-transform: uppercase;
  color: white;
  border: none;
  border-radius: 1.9vw;
  width: 3.6vw;
  font-size: 0.6vw;
  padding: 1vw;
  position: relative;
  /* top: 6.3vw !important; */
  height: 3.5vw;
  padding-left: 1.4vw;
}
.aygitem{
  border-radius: 0.2vw;
  margin-top: 4vw;
  height: 15.5vw;
  width: 21vw;
  height: 20.2vw;
  margin-bottom: 0;
  margin-left: 0.26vw;
}
.aygflex{
  /* background: red; */
  /* opacity: 0.2; */
}
.str{
  /* height: 102vh !important; */
  /* background-position-y: 21.8vw; */
  background: none !important;
  /* height: 149vh !important; */
}
.Route_Link_Block a{
  text-transform: none;
  padding-bottom: 1.8vw;
  padding-top: 1.9vw;
  text-decoration: none;
  font-size: 1vw;
  display: block;
  /* border: 1px solid red; */
  color: white;
  font-family: 'DejaVuSans-Bold';
}
.dp_2{
  color: #808080;
  text-transform: none;
  font-family: 'Bokonique-Light';
  position: relative;
  /* left: 7.8vw; */
  font-size: 0.8vw;
  margin: auto 0 auto 0;
  text-align: center;
  padding-top: 0;
  width: 25%;
}
.dp{
  font-family: 'Bokonique-Bold';
  text-transform: none;
  font-size: 1.0vw;
  color: black;
  display: block;
  margin: 0 0 0 auto;
  width: 70%;
  text-align: left;
}

.dp_one {
  margin-left: 28px;
}

.sectionTitle{
  color: #585858;
  font-family: 'Bokonique-Light';
  padding-top: 0.4vw;
  left: 24.4vw;
  text-transform: uppercase;
  right: 0;
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.2vw;
  text-align: center;
}
.SeachResults{
  text-transform: none;
  color: #585858;
  font-family: 'Bokonique-Light';
  padding-top: 0.4vw;
  left: unset !important;
  right: 0;
  position: absolute;
  width: fit-content;
  font-size: 0.8vw;
  text-align: center;
  /* margin: 0; */
}

/* .garden .SeachResults{
  left: 0;
  right: -20.5vw;
  position: absolute;
} */

video.bgVideo{
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.ResultsContainer {
    display: flex;
    height: 1.9vw;
    margin-top: -0.3vw;
    width: 66.6vw;
    /* color: #3B4A3F; */
    /* background: red; */
    /* opacity: 0.5; */
    position: absolute;
    left: 23.7vw;
    margin-left: 0.6vw;
}
.ResultsContainer .categoryTitle{
  font-family: 'Bokonique-Bold';
  color: #3B4A3F;
  font-size: 1.5vw;
}

.bc1{
  background-color: #30483C ;
}
.bc1>a{
  padding-bottom: 5%;
  padding-top: 5.9%;
}

.bc1 > a.center__text {
  padding-top: 1.9vw;
}

.bc2{
  background-color: #18B463 ;
}
.bc3{
  background-color: #00CB8E ;
}
.bc4{
  background-color: #69D680 ;
}

/* 2 container */
.cnt2{
  background:white;
  background-color: white;
  color: #444444;
  height: auto;
  background-position-y: -57vw;
  text-align: center;
}

.MobileClimatContainer{
  display: none;
}
.ClimateContainer{
  display: block;
}
.logos_container div{
  /* float: left; */
}
.logos_container{
  display: flex;
  justify-content: center;
}
/* .LogoImgBlock:nth-child(2){
  height: 6.5vw;
  padding-left: 10.4vw;
  position: relative;
  top: -1.1vw;
}
.LogoImgBlock:nth-child(3){
  padding-left: 10.8vw;
    height: 4.3vw;
    position: relative;
    top: 0.5vw;
}
.LogoImgBlock:nth-child(4){
  padding-left: 10.1vw;
    height: 4.1vw;
    position: relative;
    top: 0.7vw;
    left: 0.9vw;
} */
.LogoImgBlock img{
  filter: grayscale(100%);
}
.LogoImgBlock {
  height: 6.1vw;
  padding: 0 4.5vw;
  margin-top: 55px;
}
.LogoImgBlock img:hover{
  cursor: pointer;
  filter: grayscale(0%);
}
.Footer_Container div{
  /* display: flex; */
  color: black;
  /* float: left; */
}
.Footer_Container{
  position: relative;
  display: flex;
  /* text-decoration: un; */
}
.trainingItem {
  display: block;
  border-radius: 0.2vw;
  /* border-bottom: 1px solid #EBEBEB; */
  margin-top: 4vw;
  height: 15.5vw;
  width: auto;
  height: auto;
  /* margin-bottom: 11.2vw; */
  margin-left: -6.8vw;
  /* background: grey; */
}
.trgshow{
  height: 1.2vw;
  margin-top: 20px;

}
.trgshow a{
  margin: 0;
}
.BurgerMenu{
  display: none;
}
.My-Footer{
  /* position: relative; */
  font-family: 'DejaVuSans';
  text-transform: none;
  margin-top: 8vw;
  padding-left: 8.9vw;
}
.mytxt{
  display: none;
}
.MobileCategoriesContainer{
  display: none;
}
.Footer_Logo{
  background-image: url('../../views/images/logos/Logo.png');
  /* width: 3vw; */
    padding: 2vw;
    
    background-size: 100%;
    margin-right: 1.3vw;
    background-repeat: no-repeat;
}
.Footer_adress{
  font-size: 0.7vw;
  font-weight: bold;
}
.Footer_Contacts{
  font-size: 0.7vw;
}
.LogoImgBlock img{
  min-height: 100%;
  max-height: 100%;
}
.Logos_nav{
  /* padding-left: 1.6vw; */
  /* overflow: hidden; */
  margin-top: calc(8.5vw - 55px);
    /* position: relative; */
    /* top: 19.4vw; */
}

.About_text_container{
  font-family: 'Bokonique-Light';
  text-transform: none;
  color: black;
}
.txt_a{
  font-size: 2.5vw;
  margin-top: 65px;
  color: black;
  font-weight: 400;
  margin-bottom: 35px;
}

.About_text_container>p{
  font-size: 1.1vw;
  width: 61%;
  margin: 0 auto 30px;
}

.About_text_container h3{
  font-size: 2.5vw;
  margin-top: 65px;
  color: #000;
  font-weight: 400;
  margin-bottom: 35px;
}

.TitleMobile p{
  margin: 0;
}
.seeMoreBtn{
  display: none;
}