@media(max-width:1024px) {


    body {
      /* background-color: olive; */
      overflow-x: hidden;
      width: 100vw;
      height: 100vh;
    }
    .DisCriptionBlock{
        height: 25%;
    }
    .HomePageNav{
        display: block !important;
        position: relative !important;
        
      }
      .HomePageNav .Routes_Container {
        height: auto !important;
      }
      #overBody{
          overflow: hidden;
      }

    .ctext{

    }
    .flex-container{
        padding: 0 14px;
    }
    .aygitemImg {
        height: 260px !important;
        overflow: hidden;
    }
    .aygitem{
        margin-bottom: 30px;
    }
    .dp{
        font-size: 19px;
        margin: auto;
    }
    .flexItemMenu {
        height: 347px;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        border-radius: 3px;
    }
    .InMenu{
        display: flex;
        flex-wrap: wrap;
    }
    .ResultsContainer {
        display: flex;
        height: fit-content;
        /* margin-top: -5.3vw !important; */
        width: 100%;
        /* color: #3B4A3F; */
        /* background: red; */
        box-sizing: border-box;
        /* opacity: 0.5; */
        position: static;
        left: 0;
        /* left: 24.7vw; */
        padding-left: 14px;
        padding-right: 14px;
    }
    
    .progm .ResultsContainer {
        background: red !important;
    }
    
    .SeachResults {
        font-size: 12px;
        text-align: right;
        position: static;
        right: 2vw;
        width: 100%;
        padding-top: 2vw !important;
        margin: 0;
        /* margin-left: 15vw; */
    }

    .MyBodyContainer {
        margin-top: 10.5vw;
    }

    .flexfixer.training .rl {
        margin-left: 0px;
    }

    .categoryTitle {
        font-size: 22px !important;
    }

    .dp_2 {
        font-size: 16px;
        margin: auto;
        padding: 0;
    }
    .agnb {
        background-color: #5BCA90;
        text-transform: uppercase;
        color: white;
        border: none;
        display: flex;
        /* top: -19vw; */
        /* margin-top: 54.5vw; */
        align-self: center;
        align-items: center;
        border-radius: 46px;
        width: 20.6vw !important;
        font-size: 13px;
        padding: 39px;
        position: relative;
        top: -47.7vw !important;
        padding-left: 8vw;
        height: 5vw;
    }
    .aygishadow {
        position: absolute !important;
        height: 260px !important;
        background: none !important;
    }
    .fixc{
        width: fit-content;
    }
    .flex-item{
        height: 256px;
        width: 100%;
        margin-bottom: 10vw;    
        margin-right: 0;
    }

    .flex-item:nth-last-child(-n + 3){
        margin-bottom: 10vw;
    }
    .flex-item .Shadow {
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.32);
    }
    
    .buter a{
        font-size: 3vw;
        text-align: center;
        padding: 14.4px 0 !important;
    }
    .mbct{
        height: 170px;
        width: 100%;
        background: none;
        margin-top: 68px;

    }
    .flex-item .center_btn{
        transition: all 0.3s;
        opacity: 1;
    }
    .mycontext p, .flex-item:hover p{
        top:-1vw;
    }
    .aygflex{
        /* margin-top: 5vw; */
    }
    .MyIcon{
        opacity: 0;
    }
    .aygitem:hover .center_btn{
        opacity: 0;
    }
    .tragr{
        /* text-align: left;
        padding-top: 0 !important;
        font-size: 19px !important; */
        display: none;
    }
    .trainingItem::after{
        width: 100%;
        left: 0;
        top: 5vw;
    }
    .ResultsContainer .categoryTitle{
        font-family: 'Bokonique-Bold';
        color: #3B4A3F;
        font-size: 1.5vw;
      }
    .trainingItem{
        margin: 0;
        margin-bottom: 10vw;
    }
    .contexter{
        margin: 0;
    }

    .flex-container.training .contexter{
        display: block;
    }

    .flex-container.training .contexter .rb{
        margin-left: 0;
        width: 100%;
    }

    .flex-container.training .contexter .rb .desc{
        font-size: 0.8rem;
        margin-top: 25px;
    }

    .publications .flex-item{
        margin-right: 0;
        display: flex;
        align-items: center;
    }
    .MobileClimatContainer {
        display: block;
        padding: 0 14px;
        overflow: hidden;
        /* background: red; */
        height: auto;
        width: 100%;
        margin-top: 60px;
    }
    .ClimatImgMobile{
        /* width: 50%; */
        height: 50%;
    }
    .progItem {
        border-radius: 0.2vw;
        margin-top: 4.1vw;
        height: fit-content;
        width: 100%;
        display: block;
        /* height: 241px; */
        margin-bottom: 20px;
        /* margin-left: 3.2vw; */
        /* background: grey; */
    }
    .rb{
        width: auto;
    }
    .rl, .rb{
        font-size: 16px;
    }
    .titlprog{
        /* margin-left: 0; */
        font-size: 16px;
    }
    .regcont{
        display: block;
        margin-bottom: 3vw;
    }
    .myprog {
        position: static;
        margin-left: 0vw !important;
        /* margin-top: 4vw; */
    }
    
    .ImageBlockContainer,.progItem .ImageBlockContainer {
        overflow: hidden;
        /* background: yellow; */
        /* background: yellow; */
        margin-bottom: 3vw;
        height: 250px;
        width: 100%;
    }
    .compImgTitle{

    }
    #VideoCloser {
        position: absolute;
        top: 20%;
        left: 93%;
    
    }
    #VideoCloser::after, #VideoCloser::before{
        
    width: 1px;
    height: 19px;

    }
    .ProgNameContainer {
        width: 95vw;
        DISPLAY: none;
        position: static;
        /* margin-bottom: 6vw; */
        /* margin-top: 78vw; */
    }
    .videoPlayerMobile{
        height: 50% !important;
        width: 100vw !important;
        position: absolute;
      }
    .swimg {
        height: 15px;
        width: 4.2vw;
        /* background-color: red; */
        display: flex;
        background-repeat: no-repeat;
        background-size: 100%;
        margin-left: 9px;
        margin-top: 0.9vw;
    }
    .karpos{
        height: fit-content;
        margin-bottom: 0;
    }
    .trgshow{
        display: block;
    }
    .trgshow--desktop {
        display: none;
    }
    .showLink {
        display: flex;
        font-family: 'Bokonique-Bold';
        color: #83d7ab;
        margin-left: 0;
        text-transform: uppercase;
        font-size: 16px;
        /* color: red; */
        width: 50.6vw;
    }
    .InfoTextContainer {
        width: 100%;
        height: 62.5vw;
        margin-top: 2vw;
    }
    .MyButtonShow{
        height: auto;
    }
    .fxt{
        margin: 0;
    }
    .prgInfo{
        margin-top: 0;
        width: auto !important;
        height: fit-content !important;
    }
    .mytext_1{
        font-size: 16px;
        width: auto;
        margin-left: 0;
        margin-bottom: 3vw;
    }
    .progItem .TitleInfo, .titl{
        font-size: 16px;
        margin: 0;
        margin-bottom: 6px !important;
    }
    .progItem .MyButtonShow a{
        font-size: 15px;
        margin: 0;
        margin-bottom: 2.4vw !important;
    }
    .progItem .MyInfoContainer{
        height: fit-content;
    }
    .progItem .MyButtonShow{
        margin-top: 10px;
    }
    .MyInfoContainer {
        height: fit-content;
    }
    .TitleInfo {
        width: 100%;
        height: fit-content;
        /* height: 3.4vw; */
    }

    .gover .TitleInfo.compImgTitle{
      display: block;
    }
    .gover .MyInfoContainer{
      width: initial;
    }
    .mytitle_2{
        font-size: 5vw;
        position: absolute;
        top: 23px;
        left: 108px;
    }
    .ClimatImage{
        margin-top: 1vw;
        width: 33vw;
        height: 94%;
        justify-content: center;
        align-items: center;
        background-color: white;
        display: flex;
        margin-right: 3vw;
        margin-left: 1vw;
    }
    .blck_1 p:nth-child(1){
        font-size: 16px;
        font-family: 'Bokonique-Regular';
        color: black;
        /* margin-top: 8vw; */
        margin-bottom: 0;
    }
    .blck_1 p{
        /* margin: 0 !important; */
    }
    .blck_2 .sombl{
        font-size: 34px;
    }
    .blck_2 .sombl::after {
        content: 'o';
        font-size: 4.2vw;
        font-weight: bold;
        position: absolute;
        margin-top: 7px;
    }
    .blck_1 p:nth-child(2){
        font-family: 'Bokonique-Light';
        font-size: 16px;
        color: black;
        margin-bottom: 0;
    }
    .blck_1{
        background: #F8F6F5;
        width: 37vw;
        height: fit-content;
        align-self: center;
      }
      .blck_2{
        background-color: #F8F6F5;
        width: 37vw;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    .ClimateBlocer{
        height: 108px;
        display: flex;
        background: #F8F6F5;
        width: 100%;
    }
    .TitleMobile p{
        margin-bottom: 12px;
    }
    .TitleMobile{
        color: black;
        font-family:'Bokonique-Regular';
        font-size: 19px;
    }
    .ClimateContainer{
        display: none;
    }
    .climat_Image_Container{
        width: 100%;
        height: 245px;
        /* margin-left: 9.4px; */
    }
    .mycontext p {
        font-size: 19px;
        /* margin-top: 75px; */
        /* margin-bottom: 30px; */
    }
    .mycontext {
        z-index: 20;
        position: relative;
        /* align-self: flex-start; */
        height: fit-content;
        top: 0;
        /* padding-top: 58px; */
        width: 100%;
    }

    .publication__mycontext {
        height: inherit !important;
    }

    .consulting__mycontext {
        height: 100% !important;
    }

    .sectionTitle{
        display: none;
    }
    .flexfixer{
        padding-left: 14px !important;
        padding-right: 14px;
    }
    .MobileCategoriesContainer{
        margin-top: 10.5vw;
    }
    .MobileCategoriesContainer select{
        width: 100%;
        word-wrap: normal;
        /* margin-left: 1.8vw; */
        padding: 0 0 2vw;
        border: none;
        font-size: 17px;
        color: #717171;
        border-bottom: 1px solid #efefef;
        text-transform: uppercase;
    }
    .MobileClimatContainer select::after{
        content: '';
    }
    .mytxt p::before{
        content: ' ';
        background-image: url('../../views/images/Icons/nexter.png');
        /* background-color: red; */
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 12px;
        display: block;
        width: 8px;
        position: absolute;
        left: -14px;
        top: 9px;
    }
    .mytxt p{
        font-size: 18px;
    }
    .mytxt{
        display: block;
    }
    .seeMoreBtn{
        display: block;
        border-color: #575354;
        border-radius: 3px;
        color: #141414;
        background-color: transparent;
        border-width: 1px;
        width: 100%;
        height: 58px;
        font-size: 17px;
        margin: 1vw 0 2vw;
    }
    .seeMoreBtn:focus{
        outline: none;
    }
    .MobileCategoriesContainer{
        display: block;
        padding-left: 14px;
        padding-right: 14px;
    }
    .BurgerMenu{
        background: #3b4a3f;
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        padding: 0;
        width: 49px;
        z-index: 100;
        right: 14px;
        align-items: center;
        margin-top: 14px;
        padding-bottom: 12px;
        justify-content: center;
    }
    .BurgerMenu div{
        background-color: #fff;
        height: 2px;
        width: 58%;
        margin-top: 8px;
    }
    .buter {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bl_2{
        width: 50% !important;
    }
    .LeftMenuContainer{
        display: none;
    }
    .iconer{
        display: none;
    }
    .TitleBlock {
        height: 245px;
        font-size: 26.5px;
        justify-content: center;
        text-align: center;
        align-items: center;
        display: flex;
        margin-top: 0;
    }
    .TitleBlock h1{
        font-size: 26.5px !important;
        line-height: inherit;
    }
    .HedaerContainer{
        display: none;
    }
    .SeterBlock {
        margin: auto;
        display: flex;
        width: -moz-fit-content;
        width: fit-content;
        padding-top: 6.6vw;
        width: calc(100% - 28px);
    }
    .My-Container.sef{
        /* background-image: url('./images/Home_Mobile.png'); */
        /* background-size: cover; */
        /* background-size: 99.8% 240%; */
        /* opacity: 0.5; */
        height: fit-content;
        /* display: flex; */
        /* width: 100vw !important; */
        
        background-repeat: no-repeat;
    }
    .My_Logo{
    width: 78px;
    align-self: flex-start;
    height: 52px;
    margin-left: 15px;
    margin-top: 8px;
    }
    .My-Container.cnt2{
        display: block;
    }
    .Container_Texts{
        /* top: 3vw;  */
        position: static;
        align-self: flex-start;
        /* display: flex; */
        /* margin: auto; */
        padding-top: 80px;
        padding-bottom: 50px;
    }
    
    .Routes_Container{
        display: flex;
        flex-wrap: wrap;
        height: 100%;
    }

    .About_text_container>p{
      font-size: 14px;
      width: 87.5%;
      margin: 0 auto 30px;
    }
    .Footer_adress{
        font-size: 14px;
        margin-bottom: 14px;
    }
    .Footer_Contacts {
        font-size: 14px;
        margin-bottom: 30px;
    }
    .Footer_Container{
        display: block;
    }
    .Footer_Container::before{
        content: " ";
        display: block;
        position: relative;
        height: 1px;
        background: #ececec;
        width: 100%;
        /* left: 0.6vw; */
        top: -35px;
    }
    .Mini_f span:nth-child(2) {
        position: static;
        float: right;
        left: 0;
        display: block;
        order: 1;
        width: 100%;
        font-size: 14px;
        text-align: right;
        margin-bottom: -21px;
    }
    .slick-slide {
        /* margin-left:50px; */
    }
    .My-Footer{
        padding-left: 14px;
        margin-top: 99px;
        padding-right: 14px;
        padding-bottom: 15px;
    }

    .swimg{
        margin-top: 2px;
        width: 13.5px;    
    }
    .ResultsContainer{
        margin-left: 0;
    }
    .Mini_f {
        text-align: left;
        margin-top: 0.4vw;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        color: #A4A4A4;
    }

    .Mini_f span:nth-child(1) {
        font-size: 14px;
        order: 2;
    }
    .Mini_f span:nth-child(2) {
        position: static;
        float: none;
        left: 0;
        display: block;
        order: 1;
        width: 100%;
        font-size: 14px;
    }
    .Footer_Logo{
        margin-bottom: 31px;
        width: 78px;
        height: 52px;
    background-size: 100%;
    margin-right: 1.3vw;
    background-repeat: no-repeat;
    }
    .Logos_nav {
        padding-left: 11px;
        /* overflow: hidden; */
        margin-top: 35px;
        padding-right: 38px;
        /* position: relative; */
        /* top: 19.4vw; */
    }
    .climat_title{
        font-size: 19px;
    }
    .ClimateContainer{
        height: 27vw;
    }
    .MenuCloser{
        z-index: 100000;
        position: absolute;
        /* background: red !important; */
        top: 5vw;
        left: 90vw;
        height: 7vw;
        width: 7vw;
        align-items: center;
        display: none;
        /* animation-delay: fle; */
    }
    .MobileFixers .MenuCloser{
        display: flex;
    }
    .Routing_nav{
        display: none;
    }
    .MobileFixers{
        display: block;
        /* display: none; */
        z-index: 10000;
        top: 0;
        left: 0;

    }
    .close{
        /* display: none; */
        /* position: absolute; */
        /* top: 20px; */
    }
    .MobileFixers .close:before, .MobileFixers .close:after{
        height: 24px !important;
        width: 2.7px !important;
    }
    .MobileFixers .Routes_Container div a{
        padding: 0 !important;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Route_Link_Block a{
        /* padding: 64px; */
    }
    .MyContainers {
        display: flex;
        justify-content: space-between;
    }
    .LogoImgBlock {
        height: 35px;
        /* padding-left: 65px; */
        margin-top: 35px;
        /* padding-right: 65px; */
    }
    .logos_container{
        display: block;
    }
    

    .About_text_container h3, .txt_a{
        font-size: 30px;
    color: black;
    }
    .Aygi_title{
        font-size: 72px;
        margin-bottom: -2.6vw;
    }
    .aygitem .center_btn a{
        padding: 16px 21px;
        font-size: 18px;
    }
    .center_btn a {
        padding: 21px;
        font-size: 18px;
    }
    .center_btn {
        border-radius: 46px;
        width: fit-content;
        padding: 0 30px;
    }
    .aygitem .center_btn {
        border-radius: 56px;
        padding: 32px;
        padding-left: 36px;
        display: flex;
        font-size: 12px;
        align-items: center;
        left: -6px;
        justify-content: center;
        top: -17%!important;
        /* margin: auto!important; */
        width: 10px !important;
    }
    .aygi_idea{
        font-size: 24px;
        margin-left: 0;
        width: 100%;
        margin-bottom: 6px;
        /* font-size: 15px; */
    }
    .ProfileBlock{
        width: 100vw;
        height: 100vh;
    }
    .Mytexts{
        width: fit-content;
    }
    .fa-chevron-left{
        display: block;
    }
    .TextDivs p:nth-child(1){
        font-size: 13px;
        width: 55%;
    }
    .InBottom {
        margin-top: 96px;
    }
    .ProfileContainer .mycloser{
        position: absolute;
    top: 20px;
    color: #3B4B3F;
    left: 4vw;
    text-shadow: none;
    }
    .ProfileContainer .mycloser::after, .ProfileContainer .mycloser::before{
        display: none;
    }
    .TextDivs{
        margin-bottom: 28px;
    }
    .ProfileDis{
        padding: 5vw;
        height:62.3%;
    }
    .TextDivs p:nth-child(2){
        font-size: 14px;
        left: 56vw;
    }
    .ImageBlock{
        left: 0;
        width: 130px;
        height: 130px;
        border-radius: 100px;
        top: 0;
        margin: auto;
        float: none;
    }
    .fxs{
        margin: auto;
    }
    .Mytexts p:nth-child(1) {
        width: 100%;
        font-size: 25px;
    }
    .Mytexts p:nth-child(2) {
        font-size: 16.7px;
    }

    .TopTitleBlock {
        background: #00CB8E;
        height: auto;
        display: flex;
    }
    .ProfileDis{
        height: 96%;
    }
    .Route_Link_Block{
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Route_Link_Block a{
        /* padding-bot: 5.8vw; */
        padding: 19px;
        font-size: 20px;
    }
    .bc1>a {
        /* padding-bottom: 1.8vw;
        padding-top: 1.9vw; */
    }
    .Routing_nav{
        position: static;
        padding: 0;
        /* opacity: 0.5; */
        bottom: 0;
    }
    .Route_Link_Block{
        width: 100vw;
    }
    .MyBodyContainerr{
        margin-top: 10.5vw;
    }

  }


  @media only screen 
    and (orientation: landscape) 
    and (max-width: 1024px){
        /* .BurgerMenu div{
            margin-top: 11px;
        } */
        .MobileFixers .Routes_Container div a{
            padding: 0 !important;
        }
        .buter a{
            font-size: 20px;
        }
        .mycontext p{
            /* font-size: 30px; */
        }
        
        /* .BurgerMenu{
            width: 11vw;
        } */
        .blck_1 p:nth-child(2){
            margin-bottom: 0 !important;
        }
        .blck_2 .sombl::after{
            font-size: 15px;
        }
        .blck_1{
            padding-left: 80px;
        }
        .ClimatImage{
            margin: auto;
            margin-left: 4px;
            margin-right: 14px;
        }
        .blck_1 p:nth-child(1){
            /* margin-top: 32px; */
        }
        .ProfileBlock {
            width: 70vw !important;
            height: 75vh !important;
            overflow: hidden;
        }
        .ProfileDis {
            height: 106vh;
        }
        #VideoCloser {
            position: absolute;
            top: 8%;
            left: 86%;
        }
        .TopTitleBlock {
            background: #00CB8E;
            height: 39.7vw;
            display: flex;
        }
        .DisCriptionBlock {
            height: 20%;
        }
        .MenuCloser{
            top: 1vw;
            left: 92vw;
        }
        .flex-item {
            /* height: 50vw; */
            width: 100%;
            margin-bottom: 10vw;
        }

        .flexItemMenu{
            width: 48%;
        }

        .flexItemMenu:nth-child(odd){
            margin-right: 4%;
        }

        .flexItemMenu {
            height: 280px;
        }

        .dp {
            font-size: 15px;
        }

        .dp_2 {
            font-size: 12px;
        }
}

@media (max-width: 500px) {
    .publications--href {
        width: 100%;
    }

    .ProfileBlock {
        overflow: scroll;
    }

    .ProfileBlock .TextDivs {
        display: block;
    }

    .ProfileBlock .TextDivs p:nth-child(2) {
        width: 100%;
        margin: auto;
        margin-top: 5px;
    }

    .ProfileBlock .InBottom {
        margin-top: 0px;
    }

    .Footer_Contacts, .Footer_adress {
        margin-bottom: 5px;
    }
}